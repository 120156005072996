<template>
	<Transition name="modal">
		<dialog
			ref="modal"
			v-if="modelValue"
			:class="{ isDragging: isDragging }"
			@keydown.esc="closeModal"
			@touchstart="handleTouchStart"
			@touchmove="handleTouchMove"
			@touchend="handleTouchEnd"
		>
			<div
				class="modal-wrapper"
				ref="modalWrapper"
				:class="{ dark: dark }"
				:style="wrapperStyle"
			>
				<div
					class="dragCloseIcon hidden-desktop"
					ref="dragCloseIcon"
					:class="{ closeOnReleaseCharged: closeOnReleaseCharged }"
				>
					<TnButton
						iconOnly="close"
						:dark="dark"
						size="s"
						:disabled="!closeOnReleaseCharged"
					></TnButton>
				</div>
				<div
					class="backdrop"
					@click="closeModal"
					aria-label="Backdrop. Click to close modal"
				></div>
				<div
					class="modal-content"
					ref="modalContent"
					:class="cls"
					:style="style"
				>
					<TnButton
						v-if="closable"
						@click="closeModal"
						class="close"
						aria-label="Close modal"
						iconOnly="close"
						:dark="dark"
						tertiary
						size="s"
					>
					</TnButton>
					<div
						class="dragBar hidden-desktop"
						v-if="closable"
						@touchmove="handleTouchMoveDragBar"
					>
						<div class="bar"></div>
					</div>
					<div
						class="overflow-container"
						:class="{ disableModalContentScroll: disableModalContentScroll }"
						ref="overflowContainer"
					>
						<slot></slot>
					</div>
				</div>
			</div>
		</dialog>
	</Transition>
</template>

<script>
const closeOnDragAmount = 120;
export default defineNuxtComponent({
	name: "Modal",
	props: {
		modelValue: { type: Boolean },
		width: {},
		size: {
			type: String,
			validator(value) {
				return ["xs", "s", "m", "l"].includes(value);
			},
		},
		dark: { type: Boolean, default: false },
		openDelay: { type: Number, default: 0 },
		zIndex: { type: Number },
		closable: { type: Boolean, default: true },
	},
	data() {
		return {
			startY: 0,
			isDragging: false,
			deltaY: 0,
			cachedDeltaY: 0,
			disableModalContentScroll: false,
			closeOnReleaseCharged: false,
			closeTriggeredByKeyboard: false,
		};
	},
	methods: {
		openModal() {
			this.handleTelmiZIndex();
			this.$refs.modal.showModal();
			//Prevent autofocus on first element.
			document.activeElement.blur();
			//Tab click will focus first element in modal.
			this.$refs.modal.focus();
		},
		closeModal(event) {
			if (!this.closable) {
				return;
			}
			//Close triggered by Modal.vue -> escape key, close button or drag to close
			if (event) {
				if (event?.key || (event.pointerType === "" && event.pointerId === -1)) {
					this.closeTriggeredByKeyboard = true;
				}
				this.$emit("update:modelValue", false);
			}
			//Close triggered due to v-model change
			else {
				this.$refs.modal.close();
				this.$emit("update:modelValue", false);
				if (!this.closeTriggeredByKeyboard) {
					//Timeout required due to modal chaining, gives dialog time to get element that originally triggered the first modal.
					setTimeout(() => {
						//Don´t autofocus element that triggered modal if not keyboard navigation.
						document.activeElement.blur();
					}, 1);
				}
				this.closeTriggeredByKeyboard = false;
			}
		},
		handleTelmiZIndex() {
			try {
				if (typeof window.configureChat === "function") {
					window.configureChat({ zIndex: "1004 !important" });
				}
			} catch (error) {
				console.error(error);
			}
		},
		handleTouchStart(event) {
			if (!this.closable) {
				return;
			}
			this.$refs.modalContent.style.transition = "unset";
			this.$refs.dragCloseIcon.style.transition = "unset";
			this.startY = event.touches[0].clientY;
			this.isDragging = true;
		},
		handleTouchMoveDragBar(event) {
			this.handleTouchMove(event, true);
		},
		handleTouchMove(event, isDragBar) {
			if (!this.isDragging || !this.closable) {
				this.deltaY = 0;
				return;
			}
			const currentY = event.touches[0].clientY;
			this.deltaY = currentY - this.startY;
			if ((this.$refs.overflowContainer.scrollTop === 0 && this.deltaY > 0 && !this.disableDragToClose) || isDragBar) {
				this.disableModalContentScroll = true;
				event.preventDefault(); //Prevent iOS Safari navnbar popup.
				this.$refs.modalContent.style.transform = `translateY(${this.deltaY}px)`;
				this.$refs.dragCloseIcon.style.bottom = this.$refs.modalContent.clientHeight - 50 - this.deltaY / 3 + "px";
				this.$refs.dragCloseIcon.style.opacity = this.deltaY / closeOnDragAmount;
				this.closeOnReleaseCharged = this.deltaY / closeOnDragAmount >= 1;
			} else {
				this.disableDragToClose = true;
			}
		},
		handleTouchEnd() {
			if (!this.closable) {
				return;
			}
			this.$refs.modalContent.style.transition = "all 200ms";
			this.$refs.dragCloseIcon.style.transition = "all 200ms";
			if (this.closeOnReleaseCharged) {
				this.$refs.modalContent.style.transform = `translateY(100%)`;
				this.closeModal(true);
			} else if (this.deltaY != 0 && !this.closeOnReleaseCharged) {
				this.$refs.modalContent.style.transform = `translateY(0)`;
				this.$refs.dragCloseIcon.style.opacity = 0;
			}
			this.disableDragToClose = false;
			this.isDragging = false;
			this.closeOnReleaseCharged = false;
			this.disableModalContentScroll = false;
		},
		lockRootElementScroll() {
			const rootElement = document.getElementsByTagName("html")[0];
			const headerElement = document.getElementsByClassName("top-menu-bar")[0];
			if (headerElement) {
				headerElement.style.width = `${rootElement.clientWidth}px`;
			}
			if (rootElement) {
				rootElement.style.width = `${rootElement.clientWidth}px`;
				this.$refs.modalWrapper.style.width = `${rootElement.clientWidth}px`;
				rootElement.classList.add("modal-active");
			}
		},
		unlockRootElementScroll() {
			const rootElement = document.getElementsByTagName("html")[0];
			const headerElement = document.getElementsByClassName("top-menu-bar")[0];
			if (rootElement) {
				rootElement.classList.remove("modal-active");
				rootElement.style.width = "auto";
			}
			if (headerElement) {
				headerElement.style.width = "auto";
			}
		},
	},
	computed: {
		style() {
			if (this.width) {
				return {
					width: this.width + "px",
				};
			}

			return {};
		},
		cls() {
			if (!this.width && this.size) {
				return `size-${this.size}`;
			}
			return "";
		},
		wrapperStyle() {
			const style = {};
			if (this.zIndex) {
				style.zIndex = this.zIndex;
			}
			return style;
		},
	},
	watch: {
		modelValue(newValue) {
			if (newValue) {
				setTimeout(() => {
					this.lockRootElementScroll();
					this.openModal();
				}, this.openDelay);
			} else {
				this.unlockRootElementScroll();
				this.closeModal();
			}
		},
	},
	beforeUnmount() {
		this.unlockRootElementScroll();
	},
});
</script>

<style lang="scss">
.modal-active {
	overflow: hidden;
}
</style>

<style lang="scss" scoped>
dialog {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 0;
	padding: 0;

	&.isDragging {
		overflow: hidden;
	}
}

.modal-wrapper {
	position: fixed;
	inset: 0;
	display: flex;
	place-content: center center;
	align-items: center;

	.modal-content {
		z-index: 2;
		max-height: 90vh;
		max-height: 90dvh;
		max-width: 90%;
		position: fixed;

		.close {
			position: absolute;
			top: $spacing-s;
			right: $spacing-m;
			z-index: 4;
		}

		&.size-xs {
			max-width: $size-screen-xs;
		}

		&.size-s {
			max-width: $size-screen-s;
		}

		&.size-m {
			max-width: $size-screen-m;
		}

		&.size-l {
			max-width: $size-screen-l;
		}

		:deep(img) {
			max-width: 100%;
		}

		.overflow-container {
			height: 100%;
			overflow: auto;
			padding: $spacing-2xl;
			max-height: 90vh;
			max-height: 90dvh;
			overscroll-behavior: contain;
			background: $color-neutrals-white;
			border-radius: $border-radius-m;

			@include breakpoint(mobile) {
				border-radius: $border-radius-l $border-radius-l 0 0;
				padding: $spacing-xl $spacing-l;

				&.disableModalContentScroll {
					overflow: hidden;
				}
			}
		}

		@include breakpoint(mobile) {
			max-width: 100%;
			width: 100%;
			max-height: 95vh;
			max-height: 95dvh;
			margin-top: auto;
		}
	}

	.dragBar {
		position: absolute;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 3;

		.bar {
			width: 40%;
			height: 5px;
			border-radius: 4px;
			background: $color-neutrals-200-tint;
			margin: 10px 0 20px;
		}
	}

	.dragCloseIcon {
		position: absolute;
		bottom: 0;
		min-width: 100%;
		display: flex;
		justify-content: center;
		z-index: 2;
		opacity: 0;

		.icon {
			border-radius: 50%;
			background-color: $color-cta-default;
			color: $color-twe-text;
			width: 35px;
			height: 35px;
			padding: 8px;
		}
	}

	.backdrop {
		position: fixed;
		inset: 0;
		height: 100vh;
		height: 100dvh;
		background: #000;
		opacity: 0.3;
		z-index: 1;
	}

	//DARK MODE
	&.dark {
		.overflow-container {
			background: $color-twe-background-secondary;
			color: $color-twe-text;
		}

		.dragBar {
			.bar {
				background: $color-neutrals-700-shade;
			}
		}

		.backdrop {
			opacity: 0.5;
		}

		@media only screen and (width >= 767px) {
			.overflow-container {
				box-shadow: inset 0 0 2px $color-neutrals-700-shade;
			}

			.overflow-container::-webkit-scrollbar {
				width: 12px;
			}

			.overflow-container::-webkit-scrollbar-track {
				background: $color-twe-background-secondary;
				border-radius: 0 $border-radius-m $border-radius-m 0;
				box-shadow:
					inset -2px 0 2px -2px $color-neutrals-700-shade,
					inset 0 -2px 2px -2px $color-neutrals-700-shade,
					inset 0 2px 2px -2px $color-neutrals-700-shade;
			}

			.overflow-container::-webkit-scrollbar-thumb {
				background: $color-neutrals-400-tint;
				border-radius: $border-radius-m;

				&:hover {
					background: $color-neutrals-500-core;
				}
			}
		}
	}

	@include breakpoint(mobile) {
		align-items: flex-end;
	}
}

.modal-enter-active,
.modal-leave-active {
	z-index: 1006;
	transition: opacity 200ms;

	.backdrop {
		transition: opacity 200ms;
	}

	.modal-content {
		transition: all 200ms;
	}

	.dragCloseIcon {
		transition: all 200ms;
	}
}

.modal-enter-from,
.modal-leave-to {
	opacity: 1;

	.backdrop {
		opacity: 0 !important;
	}

	.modal-content {
		transform: translateY(20px) !important;
		opacity: 0;

		@include breakpoint(mobile) {
			transform: translateY(100%) !important;
		}
	}

	.dragCloseIcon {
		animation: bumpFade 300ms;
	}
}

@keyframes bumpFade {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(0);
	}
}
</style>
